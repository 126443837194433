import React, { useEffect } from 'react'

const Footer = () => {
  useEffect(() => {
    const script = document.createElement("script")

    script.src = "https://unpkg.com/website-carbon-badges@1.1.3/b.min.js"
    script.defer = true

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  return (
    <>
      <footer className="site-footer clearfix">
        <div className="footer-social">
          <ul className="footer-social-links">
            <li>
              <a href="https://twitter.com/nbrites_" target="_blank" rel="noopener noreferrer">Twitter</a>
            </li>
            <li>
              <a href="https://www.linkedin.com/in/nbrites/" target="_blank" rel="noopener noreferrer">LinkedIn</a>
            </li>
            <li>
              <a href="https://medium.com/@nbrites" target="_blank" rel="noopener noreferrer">Medium</a>
            </li>
            <li>
              <a href="https://mastodon.social/@nbrites" target="_blank" rel="noopener noreferrer">Mastodon</a>
            </li>
          </ul>
        </div>
        <div className="footer-copyrights">
          <p>© 2019-2023 All rights reserved. Nuno Brites</p>
        </div>
        <div id="wcb" className="carbonbadge"></div>
      </footer>
    </>
  );
};

export default Footer