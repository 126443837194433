import React from 'react'

const Home = () => {
  return (
    <div id="content" className="page-content site-content single-post" role="main">
      <article id="post-157" className="post-157 page type-page status-publish hentry">
        <div className="entry-content">
          <div className="fw-page-builder-content">
            <section className="fw-main-row  ">
              <div className="fw-container">
                {/* Who I am */}
                <div className="row">
                  <div className=" col-xs-12 col-sm-12 ">
                    <div id="col_inner_id-5ea341dc37ec6" className="fw-col-inner" data-paddings="15px 0px 15px 0px">
                      <div id="home_content_954218bfd87cb3b458721f4a70135965" className="home-content">
                        <div className="row flex-v-align">
                          <div className="col-sm-12 col-md-5 col-lg-5">
                            <div className="home-photo">
                              <div className="hp-inner"></div>
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-7 col-lg-7">
                            <div className="home-text hp-left">
                              <div>
                                <div className="item">
                                  <h4>Software Engineer</h4>
                                </div>
                              </div>
                              <h1>Nuno Brites</h1>
                              <p>Born in Lisbon, Portugal, I've been working as a fullstack software engineer and consolidating my knowledge in technologies related to frontend and backend development.</p>
                              <p>On my day to day work I like to apply a pragmatic approach and break topics into small parts to solve them incrementally. </p>
                              <p>My areas of interest include: web applications, distributed systems, and product engineering.</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* What I do */}
              <div className="row">
                <div className=" col-xs-12 col-sm-12 ">
                  <div id="col_inner_id-5ea341dc3835b" className="fw-col-inner" data-paddings="0px 0px 0px 0px">
                    <div className="fw-divider-space" style={{ "paddingTop": "50px" }}></div>
                    <div className="block-title">
                      <h2>What I Do</h2>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className=" col-xs-12 col-sm-6 ">
                  <div id="col_inner_id-5ea341dc3878e" className="fw-col-inner" data-paddings="0px 0px 0px 0px">
                    <div id="info-list-5ea341dc38959" className="info-list-w-icon">
                      <div className="info-block-w-icon">
                        <div className="ci-icon">
                          <i className="fa fa-code" aria-hidden="true"></i>
                        </div>
                        <div className="ci-text">
                          <h4>Frontend</h4>
                          <p>Started by working with plain JavaScript and jQuery and when modern frontend frameworks/libraries started to appear I've moved to Angular and React using TypeScript.</p>
                        </div>
                      </div>
                      <div className="info-block-w-icon">
                        <div className="ci-icon">
                          <i className="fa fa-server" aria-hidden="true"></i>
                        </div>
                        <div className="ci-text">
                          <h4>Backend</h4>
                          <p>I've worked with monolithic and microservices oriented web applications. The progamming language I'm used to work with is TypeScript with Node.js.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className=" col-xs-12 col-sm-6 ">
                  <div id="col_inner_id-5ea341dc38a6e" className="fw-col-inner" data-paddings="0px 0px 0px 0px">
                    <div id="info-list-5ea341dc38b28" className="info-list-w-icon">
                      <div className="info-block-w-icon">
                        <div className="ci-icon">
                          <i className="fa fa-database" aria-hidden="true"></i>
                        </div>
                        <div className="ci-text">
                          <h4>Database</h4>
                          <p>At the database level I work with relational databases like PostgreSQL.</p>
                        </div>
                      </div>
                      <div className="info-block-w-icon">
                        <div className="ci-icon">
                          <i className="fa fa-flask" aria-hidden="true"></i>
                        </div>
                        <div className="ci-text">
                          <h4>Testing</h4>
                          <p>Tests are fundamental to guarantee product quality, they make software development more predictable and can also work as documentation.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </article>
    </div>
  )
}

export default Home